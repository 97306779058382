import { IRouteConfig, IRoutingConfig } from '../../_shared/models/models-index';
import { Enums } from '../../_shared/enums/enums';

export class RoutingConfig implements IRoutingConfig {
  allRoles = ["System Administrator", "Corporate", "Region Manager", "Area Manager", "District Manager", "Dealer", "Dealer Group", "Agency", "System Analyst"];
  allRolesExceptAgency = ["System Administrator", "Corporate", "Region Manager", "Area Manager", "District Manager", "Dealer", "Dealer Group", "System Analyst"];

  public routes: IRouteConfig[] = [
    {
      id: 'logout',
      category: 'logout',
      iconClass: null,
      displayName: null,
      displayInNav: false,
      defaultRoute: '/logout',
      roles: this.allRoles,
      children: [],
      routeData: {
        redirectOnLogout: true,
      }
    },
    {
     id: 'website',
     category: 'website',
     default: true,
     defaultRoute: '/website/website-overview',
     displayName: 'Website',
     displayInNav: true,
     iconClass: 'fa fa-globe',
     roles: this.allRolesExceptAgency,
     children: [
       {
         parent: '/website',
         route: 'website-overview',
         displayName: 'Overview',
         pdfEnabled: false,
         breadcrumbHelpEnabled: true,
         iconClass: 'fa fa-users',
         roles: undefined
       },
       {
         parent: '/website',
         route: 'scorecard',
         displayName: 'Scorecard',
         iconClass: 'fa fa-list',
         breadcrumbHelpEnabled: true,
         roles: undefined
        },
      ]
    },
    {
      id: 'digAd',
      category: 'digAd',
      default: true,
      defaultRoute: '/digAd/dashboard',
      displayName: 'Digital Advertising',
      displayInNav: true,
      iconClass: 'fa fa-desktop',
      roles: this.allRoles,
      children: [
        {
          parent: '/digAd',
          route: 'dashboard',
          displayName: 'Digital Advertising Overview - Tier 3',
          pdfEnabled: false,
          iconClass: 'fa fa-users',
          breadcrumbHelpEnabled: true,
          roles: this.allRolesExceptAgency
        },
        {
          parent: '/digAd',
          route: 'tier2-dashboard',
          displayName: 'Digital Advertising Overview – Chevy Tier 2',
          pdfEnabled: false,
          iconClass: 'fa fa-users',
          breadcrumbHelpEnabled: true,
          roles: this.allRoles
        },
      ]
    },
    {
        id: 'sales',
        category: 'sales',
        default: true,
        defaultRoute: '/sales/forecasting',
        displayName: 'Sales',
        displayInNav: true,
        iconClass: 'fa fa-usd',
        roles: this.allRolesExceptAgency,
        children: [
          {
            parent: '/sales',
            route: 'forecasting',
            displayName: 'Overview',
            pdfEnabled: true,
            iconClass: 'fa fa-users',
            roles: undefined,
            breadcrumbHelpEnabled: true
          }
        ]
      },
    {
      id: 'provider-tools',
      category: 'provider-tools',
      default: true,
      defaultRoute: '/provider-tools/chat-overview-v2',
      displayName: 'Provider Tools',
      displayInNav: true,
      iconClass: 'fa fa-building',
      roles: this.allRolesExceptAgency,
      children: [{
        parent: '/provider-tools',
        route: 'chat-overview',
        displayName: 'Chat',
        pdfEnabled: true,
        breadcrumbHelpEnabled: true,
        iconClass: 'fa fa-users',
        roles: this.allRolesExceptAgency
      }, {
        parent: '/provider-tools',
        route: 'trade-in-overview',
        displayName: 'Trade-In',
        pdfEnabled: true,
        breadcrumbHelpEnabled: true,
        iconClass: 'fa fa-exchange',
        roles: this.allRolesExceptAgency
      }]
    },
    {
      id: 'management',
      category: 'management',
      defaultRoute: '/management/users',
      displayName: 'Management',
      displayInNav: true,
      iconClass: 'fa fa-cog',
      roles: ['System Administrator', "System Analyst"],
      children: [
        {
          parent: '/management',
          route: 'content',
          displayName: 'Content',
          iconClass: 'fa fa-code',
          roles: ['System Administrator', "System Analyst"]
        }, {
          parent: '/management',
          route: 'report-views-configuration',
          displayName: 'Report Views',
          iconClass: 'fa fa-code',
          roles: ['System Administrator', "System Analyst"],
          children:
          [
            {
              id: 'report-views-configuration/create',
              parent: 'report-views-configuration',
              route: 'create',
              displayName: 'Create Report View',
              iconClass: 'fa fa-code',
              roles: ['System Administrator'],
            },
            {
              id: 'report-views-configuration/update',
              parent: 'report-views-configuration',
              route: 'update/:id',
              displayName: 'Edit Report View',
              iconClass: 'fa fa-code',
              roles: ['System Administrator'],
            },
          ]
        }, {
          parent: '/management',
          route: 'data-sets-configuration',
          displayName: 'Data Sets',
          iconClass: 'fa fa-code',
          roles: ['System Administrator', "System Analyst"],
          children:
          [
            {
              id: 'data-sets-configuration/create',
              parent: 'data-sets-configuration',
              route: 'create',
              displayName: 'Create Data Set',
              iconClass: 'fa fa-code',
              roles: ['System Administrator'],
            },
            {
              id: 'data-sets-configuration/update',
              parent: 'data-sets-configuration',
              route: 'update/:id',
              displayName: 'Edit Data Set',
              iconClass: 'fa fa-code',
              roles: ['System Administrator'],
            },
          ]
        }, {
          parent: '/management',
          route: 'query-configuration',
          displayName: 'Queries',
          iconClass: 'fa fa-code',
          roles: ['System Administrator', "System Analyst"],
          children:
          [
            {
              id: 'query-configuration/create',
              parent: 'query-configuration',
              route: 'create',
              displayName: 'Create Query',
              iconClass: 'fa fa-code',
              roles: ['System Administrator'],
            },
            {
              id: 'query-configuration/update',
              parent: 'query-configuration',
              route: 'update/:id',
              displayName: 'Edit Query',
              iconClass: 'fa fa-code',
              roles: ['System Administrator'],
            },
          ]
        },
        {
          parent: '/management',
          route: 'filter-configuration',
          displayName: 'Filters',
          iconClass: 'fa fa-code',
          roles: ['System Administrator', "System Analyst"],
          children:
          [
            {
              id: 'filter-configuration/create',
              parent: 'filter-configuration',
              route: 'create',
              displayName: 'Create Filter',
              iconClass: 'fa fa-code',
              roles: ['System Administrator'],
            },
            {
              id: 'filter-configuration/update',
              parent: 'filter-configuration',
              route: 'update/:id',
              displayName: 'Edit Filter',
              iconClass: 'fa fa-code',
              roles: ['System Administrator'],
            },
          ]
        },
        {
          parent: '/management',
          route: 'users',
          displayName: 'Users',
          iconClass: 'fa fa-users',
          roles: ['System Administrator', "System Analyst"]
        }
      ]
    }
  ];
}
